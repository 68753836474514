import { Crisp } from "crisp-sdk-web";
import { Component } from "react";

class CrispChat extends Component {
  componentDidMount() {
    Crisp.configure("e6c1b92b-dce1-40cd-bfdc-a4027c2a9087");
  }

  render() {
    return null;
  }
}
export default CrispChat;
