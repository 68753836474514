import { parseWithZod } from "@conform-to/zod";
import { invariantResponse } from "@epic-web/invariant";
import {
  type ActionFunctionArgs,
  type HeadersFunction,
  json,
  type LinksFunction,
  type LoaderFunctionArgs,
  type MetaFunction,
} from "@remix-run/node";
import {
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useFetchers,
  useLoaderData,
  useLocation,
  useMatches,
} from "@remix-run/react";
import { withSentry } from "@sentry/remix";
import { useEffect } from "react";
import { ClientOnly } from "remix-utils/client-only";
import { ExternalScripts } from "remix-utils/external-scripts";
import { HoneypotProvider } from "remix-utils/honeypot/react";
import { z } from "zod";

import CrispChat from "#app/components/crisp.js";
import { Header } from "#app/components/header.tsx";
import * as gtag from "#app/utils/gtags.client.ts";
import { GeneralErrorBoundary } from "./components/error-boundary.tsx";
import { Footer } from "./components/footer.tsx";
import { useToast } from "./components/toaster.tsx";
import { GlobalLoading } from "./components/ui/global-loading.tsx";
import { EpicToaster } from "./components/ui/sonner.tsx";
import { Toaster } from "./components/ui/toaster.tsx";
import { useTheme } from "./routes/resources+/theme-switch.tsx";
import fontStyleSheetUrl from "./styles/font.css?url";
import tailwindStyleSheetUrl from "./styles/tailwind.css?url";
import { setupScrollAnimations } from "./utils/animations.ts";
import { authenticator, getUserId } from "./utils/auth.server.ts";
import { ClientHintCheck, getHints } from "./utils/client-hints.tsx";
import { prisma } from "./utils/db.server.ts";
import { getEnv } from "./utils/env.server.ts";
import { honeypot } from "./utils/honeypot.server.ts";
import { combineHeaders, getDomainUrl, getUserImgSrc } from "./utils/misc.tsx";
import { useNonce } from "./utils/nonce-provider.ts";
import { type Theme } from "./utils/theme.server.ts";
import { makeTimings, time } from "./utils/timing.server.ts";
import { getToast } from "./utils/toast.server.ts";
import { useOptionalUser } from "./utils/user.ts";

export const links: LinksFunction = () => {
  return [
    { rel: "preload", href: fontStyleSheetUrl, as: "style" },
    { rel: "mask-icon", href: "/favicons/mask-icon.svg" },
    {
      rel: "alternate icon",
      type: "image/png",
      href: "/favicons/favicon-32x32.png",
    },
    { rel: "apple-touch-icon", href: "/favicons/apple-touch-icon.png" },
    {
      rel: "manifest",
      href: "/site.webmanifest",
      crossOrigin: "use-credentials",
    } as const,
    { rel: "icon", type: "image/svg+xml", href: "/favicons/favicon.svg" },
    { rel: "stylesheet", href: fontStyleSheetUrl },
    { rel: "stylesheet", href: tailwindStyleSheetUrl },
  ].filter(Boolean);
};

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return [
    { title: data ? "Carregados" : "Erro | Carregados" },
    { name: "description", content: `Your own captain's log` },
  ];
};

export async function loader({ request }: LoaderFunctionArgs) {
  const timings = makeTimings("root loader");
  const userId = await time(() => getUserId(request), {
    timings,
    type: "getUserId",
    desc: "getUserId in root",
  });

  const user = userId
    ? await time(
        () =>
          prisma.user.findUniqueOrThrow({
            select: {
              id: true,
              name: true,
              username: true,
              image: { select: { id: true } },
              credits: true,
              favorites: { select: { carId: true } },
              carSubscriptions: { select: { maker: true, model: true } },
              favoriteStations: { select: { locationId: true } },
              roles: {
                select: {
                  name: true,
                  permissions: {
                    select: {
                      entity: true,
                      action: true,
                      access: true,
                    },
                  },
                },
              },
            },
            where: { id: userId },
          }),
        { timings, type: "find user", desc: "find user in root" },
      )
    : null;
  if (userId && !user) {
    console.info("something weird happened");
    await authenticator.logout(request, { redirectTo: "/" });
  }
  const { toast, headers: toastHeaders } = await getToast(request);
  const honeyProps = honeypot.getInputProps();

  return json(
    {
      user: user ? {
        id: user.id,
        name: user.name,
        username: user.username,
        image: user.image,
        credits: user.credits,
        favorites: user.favorites,
        carSubscriptions: user.carSubscriptions,
        favoriteStations: user.favoriteStations,
        roles: user.roles,
      } : null,
      requestInfo: {
        hints: getHints(request),
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname,
      },
      ENV: getEnv(),
      toast,
      honeyProps,
      gaTrackingId: process.env.GA_TRACKING_ID,
    },
    {
      headers: combineHeaders(
        { "Server-Timing": timings.toString() },
        toastHeaders,
      ),
    },
  );
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
  const headers = {
    "Server-Timing": loaderHeaders.get("Server-Timing") ?? "",
  };
  return headers;
};

function Document({
  children,
  nonce,
  env = {},
  gaTrackingId,
}: {
  children: React.ReactNode;
  nonce: string;
  env?: Record<string, string>;
  gaTrackingId?: string;
}) {
  return (
    <html lang="pt" className={`light h-full overflow-x-hidden`}>
      <head>
        <ClientHintCheck nonce={nonce} />
        <Meta />
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover"
        />
        <meta
          name="theme-color"
          content="#000000"
        />
        <Links />
      </head>
      <body className="bg-background text-foreground">
        {!gaTrackingId ? null : (
          <>
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`}
              nonce={nonce}
            />
            <script
              async
              id="gtag-init"
              nonce={nonce}
              dangerouslySetInnerHTML={{
                __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${gaTrackingId}', {
                  page_path: window.location.pathname,
                });
              `,
              }}
            />
          </>
        )}
        <GlobalLoading />
        {children}
        <script
          nonce={nonce}
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(env)}`,
          }}
        />
        <ScrollRestoration nonce={nonce} />
        <Scripts nonce={nonce} />
        <ClientOnly fallback={""}>{() => <CrispChat />}</ClientOnly>
        <ExternalScripts />
      </body>
    </html>
  );
}

function App() {
  const data = useLoaderData<typeof loader>();
  const nonce = useNonce();
  const user = useOptionalUser();
  const matches = useMatches();
  const location = useLocation();
  const isOnCarrosPage = matches.some((m) => m.id === "routes/carros+/index");
  const isOnDashboardPage = matches.some(
    (m) => m.id === "routes/usuario+/$username_+/carros",
  );
  const isOnMapaPage = matches.some((m) => m.id === "routes/_marketing+/mapa");

  useEffect(() => {
    setupScrollAnimations();
  }, []);

  const { gaTrackingId } = data;

  useToast(data.toast);

  useEffect(() => {
    if (gaTrackingId?.length) {
      gtag.pageview(location.pathname, gaTrackingId);
    }
  }, [location, gaTrackingId]);

  return (
    <Document
      nonce={nonce}
      env={data.ENV}
      gaTrackingId={gaTrackingId}
    >
      <div className="flex h-full flex-col justify-between bg-transparent">
        <Header
          user={user ?? null}
          isOnCarrosPage={isOnCarrosPage}
          isOnDashboardPage={isOnDashboardPage}
          isOnMapaPage={isOnMapaPage}
        />

        <div className={`flex-1 ${isOnMapaPage ? 'mt-12 md:mt-12' : 'mt-16'}`}>
          <Outlet />
        </div>

        <div
          className={`${isOnCarrosPage || isOnMapaPage || isOnDashboardPage ? "hidden" : ""}`}
        >
          <Footer />
        </div>
      </div>
      <EpicToaster closeButton position="bottom-right" theme='light' />
    </Document>
  );
}

function AppWithProviders() {
  const data = useLoaderData<typeof loader>();
  return (
    <HoneypotProvider {...data.honeyProps}>
      <App />
    </HoneypotProvider>
  );
}

export default withSentry(AppWithProviders);

export function ErrorBoundary() {
  const nonce = useNonce();

  return (
    <Document nonce={nonce}>
      <GeneralErrorBoundary />
    </Document>
  );
}
