import { Link } from '@remix-run/react'
import { ArrowUp, ChevronDown } from 'lucide-react'

import { BrandMenu } from './brand-menu.tsx'

export function Footer() {
	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' })
	}

	return (
		<footer className="relative border-t border-border bg-gray-50 pb-10 pt-20 dark:bg-background">
			<div className="mx-auto max-w-7xl px-6 md:px-12">
				{/* Back to top button */}
				<div className="absolute left-1/2 top-0 -translate-x-1/2 -translate-y-1/2 transform">
					<button
						onClick={scrollToTop}
						className="flex h-12 w-12 items-center justify-center rounded-full bg-white text-primary shadow-lg transition-colors duration-300 hover:bg-primary hover:text-white"
						aria-label="Back to top"
					>
						<ArrowUp className="h-5 w-5" />
					</button>
				</div>

				<div className="flex flex-col gap-12 md:flex-row">
					{/* Company information column */}
					<div className="md:w-1/4">
						<Link
							to="/"
							prefetch="intent"
							className="font-display text-2xl font-bold text-foreground transition-all duration-300 hover:opacity-80"
						>
							<div className="flex items-center pb-3">
								<img
									className="mr-2 aspect-square h-8 w-8 md:h-9 md:w-9"
									src="/img/logo.webp"
									alt="Logo Carregados"
								/>
								Carregados
								<span className="text-primary">.</span>
							</div>
						</Link>
						<p className="mb-6 text-base font-normal text-muted-foreground">
							Marketplace de carros elétricos no Brasil.
						</p>

						<div className="mb-6 flex flex-col gap-1 text-sm font-normal text-muted-foreground">
							<div className="flex items-center gap-1">
								Atendimento
								<ChevronDown size={14} />
							</div>
							<p className="text-sm font-normal text-primary hover:underline">
								<a href="mailto:contato@carregados.com.br">
									contato@carregados.com.br
								</a>
							</p>
						</div>

						<div className="flex space-x-4">
							<a
								href="https://x.com/dnz"
								target="_blank"
								rel="noopener noreferrer"
								className="flex h-10 w-10 items-center justify-center rounded-full bg-white text-black shadow-sm transition-colors hover:bg-primary hover:text-white dark:bg-gray-800"
								aria-label="Twitter/X"
							>
								<svg viewBox="0 0 24 24" className="h-4 w-4 fill-current">
									<path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
								</svg>
							</a>
							{/* Placeholder for future social media links */}
							{/* <a 
                href="#"
                className="w-10 h-10 bg-white dark:bg-gray-800 rounded-full flex items-center justify-center text-black hover:bg-primary hover:text-white transition-colors shadow-sm"
                aria-label="Facebook"
              >
                <span className="text-xs uppercase">F</span>
              </a>
              <a 
                href="#"
                className="w-10 h-10 bg-white dark:bg-gray-800 rounded-full flex items-center justify-center text-black hover:bg-primary hover:text-white transition-colors shadow-sm"
                aria-label="Instagram"
              >
                <span className="text-xs uppercase">I</span>
              </a> */}
						</div>
					</div>

					{/* Three columns side by side */}
					<div className="grid grid-cols-1 gap-12 md:w-3/4 md:grid-cols-3">
						{/* Links column */}
						<div>
							<h3 className="text-primary-500 mb-5 text-lg font-bold">Links</h3>
							<ul className="space-y-3">
								<li>
									<Link
										prefetch="intent"
										to="/carros"
										className="text-muted-foreground transition-colors hover:text-primary"
									>
										Carros
									</Link>
								</li>
								<li>
									<Link
										prefetch="intent"
										to="/estacoes"
										className="text-muted-foreground transition-colors hover:text-primary"
									>
										Estações de Carregamento
									</Link>
								</li>
								<li>
									<Link
										prefetch="intent"
										to="/mapa"
										className="text-muted-foreground transition-colors hover:text-primary"
									>
										Mapa de Carregadores
									</Link>
								</li>
								<li>
									<Link
										prefetch="intent"
										to="/adicionar-estacao"
										className="text-muted-foreground transition-colors hover:text-primary"
									>
										Adicionar Estação
									</Link>
								</li>
								<li>
									<Link
										prefetch="intent"
										to="/mapa?planejar=viagem"
										className="text-muted-foreground transition-colors hover:text-primary"
									>
										Planejar Viagem
									</Link>
								</li>
								<li>
									<Link
										prefetch="intent"
										to="/#preco"
										className="text-muted-foreground transition-colors hover:text-primary"
									>
										Anunciar Carro
									</Link>
								</li>
								<li>
									<Link
										prefetch="intent"
										to="/dados-sobre-o-mercado-eletrico"
										className="text-muted-foreground transition-colors hover:text-primary"
									>
										Dados Sobre o Mercado
									</Link>
								</li>
								<li>
									<Link
										prefetch="intent"
										to="/#utilidades"
										className="text-muted-foreground transition-colors hover:text-primary"
									>
										Utilidades
									</Link>
								</li>
								<li>
									<Link
										to="/remover-estacao"
										className="text-muted-foreground transition-colors hover:text-primary"
									>
										Solicitar Remoção de Estação
									</Link>
								</li>
							</ul>
						</div>

						{/* Marcas column */}
						<div>
							<h3 className="text-primary-500 mb-5 text-lg font-bold">
								Por Marca
							</h3>
							<BrandMenu />
						</div>

						{/* Estados column */}
						<div>
							<h3 className="text-primary-500 mb-5 text-lg font-bold">
								Por Estado
							</h3>
							<ul className="grid grid-cols-1 gap-x-4 gap-y-3 text-sm sm:grid-cols-2">
								<li>
									<Link
										prefetch="intent"
										to={`/carros?estado=${encodeURIComponent('acre (ac)')}`}
										className="text-muted-foreground transition-colors hover:text-primary"
									>
										Acre
									</Link>
								</li>

								<li>
									<Link
										prefetch="intent"
										to={`/carros?estado=${encodeURIComponent('alagoas (al)')}`}
										className="text-muted-foreground transition-colors hover:text-primary"
									>
										Alagoas
									</Link>
								</li>

								<li>
									<Link
										prefetch="intent"
										to={`/carros?estado=${encodeURIComponent('amapá (ap)')}`}
										className="text-muted-foreground transition-colors hover:text-primary"
									>
										Amapá
									</Link>
								</li>

								<li>
									<Link
										prefetch="intent"
										to={`/carros?estado=${encodeURIComponent('amazonas (am)')}`}
										className="text-muted-foreground transition-colors hover:text-primary"
									>
										Amazonas
									</Link>
								</li>

								<li>
									<Link
										prefetch="intent"
										to={`/carros?estado=${encodeURIComponent('bahia (ba)')}`}
										className="text-muted-foreground transition-colors hover:text-primary"
									>
										Bahia
									</Link>
								</li>

								<li>
									<Link
										prefetch="intent"
										to={`/carros?estado=${encodeURIComponent('ceará (ce)')}`}
										className="text-muted-foreground transition-colors hover:text-primary"
									>
										Ceará
									</Link>
								</li>

								<li>
									<Link
										prefetch="intent"
										to={`/carros?estado=${encodeURIComponent('distrito federal (df)')}`}
										className="text-muted-foreground transition-colors hover:text-primary"
									>
										DF
									</Link>
								</li>

								<li>
									<Link
										prefetch="intent"
										to={`/carros?estado=${encodeURIComponent('espírito santo (es)')}`}
										className="text-muted-foreground transition-colors hover:text-primary"
									>
										Espírito Santo
									</Link>
								</li>

								<li>
									<Link
										prefetch="intent"
										to={`/carros?estado=${encodeURIComponent('goiás (go)')}`}
										className="text-muted-foreground transition-colors hover:text-primary"
									>
										Goiás
									</Link>
								</li>

								<li>
									<Link
										prefetch="intent"
										to={`/carros?estado=${encodeURIComponent('maranhão (ma)')}`}
										className="text-muted-foreground transition-colors hover:text-primary"
									>
										Maranhão
									</Link>
								</li>

								<li>
									<Link
										prefetch="intent"
										to={`/carros?estado=${encodeURIComponent('mato grosso (mt)')}`}
										className="text-muted-foreground transition-colors hover:text-primary"
									>
										Mato Grosso
									</Link>
								</li>

								<li>
									<Link
										prefetch="intent"
										to={`/carros?estado=${encodeURIComponent('mato grosso do sul (ms)')}`}
										className="text-muted-foreground transition-colors hover:text-primary"
									>
										MS
									</Link>
								</li>

								<li>
									<Link
										prefetch="intent"
										to={`/carros?estado=${encodeURIComponent('minas gerais (mg)')}`}
										className="text-muted-foreground transition-colors hover:text-primary"
									>
										Minas Gerais
									</Link>
								</li>

								<li>
									<Link
										prefetch="intent"
										to={`/carros?estado=${encodeURIComponent('pará (pa)')}`}
										className="text-muted-foreground transition-colors hover:text-primary"
									>
										Pará
									</Link>
								</li>

								<li>
									<Link
										prefetch="intent"
										to={`/carros?estado=${encodeURIComponent('paraíba (pb)')}`}
										className="text-muted-foreground transition-colors hover:text-primary"
									>
										Paraíba
									</Link>
								</li>

								<li>
									<Link
										prefetch="intent"
										to={`/carros?estado=${encodeURIComponent('paraná (pr)')}`}
										className="text-muted-foreground transition-colors hover:text-primary"
									>
										Paraná
									</Link>
								</li>

								<li>
									<Link
										prefetch="intent"
										to={`/carros?estado=${encodeURIComponent('pernambuco (pe)')}`}
										className="text-muted-foreground transition-colors hover:text-primary"
									>
										Pernambuco
									</Link>
								</li>

								<li>
									<Link
										prefetch="intent"
										to={`/carros?estado=${encodeURIComponent('piauí (pi)')}`}
										className="text-muted-foreground transition-colors hover:text-primary"
									>
										Piauí
									</Link>
								</li>

								<li>
									<Link
										prefetch="intent"
										to={`/carros?estado=${encodeURIComponent('rio de janeiro (rj)')}`}
										className="text-muted-foreground transition-colors hover:text-primary"
									>
										Rio de Janeiro
									</Link>
								</li>

								<li>
									<Link
										prefetch="intent"
										to={`/carros?estado=${encodeURIComponent('rio grande do norte (rn)')}`}
										className="text-muted-foreground transition-colors hover:text-primary"
									>
										RN
									</Link>
								</li>

								<li>
									<Link
										prefetch="intent"
										to={`/carros?estado=${encodeURIComponent('rio grande do sul (rs)')}`}
										className="text-muted-foreground transition-colors hover:text-primary"
									>
										Rio Grande do Sul
									</Link>
								</li>

								<li>
									<Link
										prefetch="intent"
										to={`/carros?estado=${encodeURIComponent('rondônia (ro)')}`}
										className="text-muted-foreground transition-colors hover:text-primary"
									>
										Rondônia
									</Link>
								</li>

								<li>
									<Link
										prefetch="intent"
										to={`/carros?estado=${encodeURIComponent('roraima (rr)')}`}
										className="text-muted-foreground transition-colors hover:text-primary"
									>
										Roraima
									</Link>
								</li>

								<li>
									<Link
										prefetch="intent"
										to={`/carros?estado=${encodeURIComponent('santa catarina (sc)')}`}
										className="text-muted-foreground transition-colors hover:text-primary"
									>
										Santa Catarina
									</Link>
								</li>

								<li>
									<Link
										prefetch="intent"
										to={`/carros?estado=${encodeURIComponent('são paulo (sp)')}`}
										className="text-muted-foreground transition-colors hover:text-primary"
									>
										São Paulo
									</Link>
								</li>

								<li>
									<Link
										prefetch="intent"
										to={`/carros?estado=${encodeURIComponent('sergipe (se)')}`}
										className="text-muted-foreground transition-colors hover:text-primary"
									>
										Sergipe
									</Link>
								</li>

								<li>
									<Link
										prefetch="intent"
										to={`/carros?estado=${encodeURIComponent('tocantins (to)')}`}
										className="text-muted-foreground transition-colors hover:text-primary"
									>
										Tocantins
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>

				{/* Footer bottom with copyright */}
				<div className="mt-16 flex flex-col items-center justify-between border-t border-gray-200 pt-8 dark:border-gray-800 md:flex-row">
					<p className="mb-4 text-sm text-foreground/60 md:mb-0">
						© {new Date().getFullYear()} Carregados. Todos os direitos
						reservados.
					</p>

					<div className="flex flex-wrap justify-center gap-4">
						<Link
							to="/termos-e-condicoes"
							className="text-sm text-foreground/60 transition-colors hover:text-primary"
						>
							Termos e Condições
						</Link>
						<Link
							to="/politica-de-privacidade"
							className="text-sm text-foreground/60 transition-colors hover:text-primary"
						>
							Política de Privacidade
						</Link>
					</div>
				</div>
			</div>
		</footer>
	)
}
