import { Link, Form, useSubmit } from '@remix-run/react';
import {
  Car,
  ChevronDown,
  Map,
  MapPin,
  Menu,
  Megaphone,
  PlusCircle,
  ShoppingCart,
  Tag,
  Waypoints,
  Zap,
  BatteryCharging,
  BarChart,
} from 'lucide-react';
import { useRef, useState, useEffect } from 'react';
import { ClientOnly } from 'remix-utils/client-only';

import { getUserImgSrc } from '#app/utils/misc';
import { Button } from './ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuTrigger,
} from './ui/dropdown-menu';
import { Icon } from './ui/icon';
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from './ui/navigation-menu'; // Import shadcn navigation components
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from './ui/sheet';

// Define UserType based on loader data in root.tsx
interface UserType {
  id: string;
  name: string | null;
  username: string;
  image: { id: string } | null;
}

interface HeaderProps {
  user: UserType | null;
  isOnCarrosPage?: boolean;
  isOnDashboardPage?: boolean;
  isOnMapaPage?: boolean;
  isOnMarketingPage?: boolean; // Assuming you might need this later
}

// Navigation data moved here
const navigation = [
  {
    name: 'Carros',
    icon: Car,
    items: [
      { name: 'Ver Carros', href: '/carros', icon: Car, description: 'Explore os carros elétricos disponíveis.' },
      {
        name: 'Anunciar Carro',
        href: '/anuncios', // This will be dynamically adjusted based on user auth later
        icon: Megaphone,
        description: 'Anuncie seu veículo elétrico para venda.',
      },
    ],
  },
  {
    name: 'Mapa de Carregadores',
    icon: Map,
    items: [
      { name: 'Mapa de Estações', href: '/mapa', icon: MapPin, description: 'Encontre pontos de recarga próximos.' },
      {
        name: 'Planejar Viagem',
        href: '/mapa?planejar=viagem',
        icon: Waypoints,
        description: 'Planeje rotas com paradas para recarga.',
      },
      {
        name: 'Adicionar Estação',
        href: '/adicionar-estacao',
        icon: PlusCircle,
        description: 'Contribua adicionando novas estações.',
      },
      {
        name: 'Dados do Mercado',
        href: '/dados-sobre-o-mercado-eletrico',
        icon: BarChart,
        description: 'Visualize estatísticas sobre o mercado de elétricos.',
      },
    ],
  },
];

// UserDropdown component moved here
function UserDropdown({ user }: { user: UserType }) {
  const submit = useSubmit();
  const formRef = useRef<HTMLFormElement>(null);

  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger asChild>
        <Button
          asChild
          variant="ghost"
          className="rounded-full p-0 h-auto hover:bg-transparent group" // Added group for ChevronDown animation
        >
          <Link
            prefetch="intent"
            to={`/usuario/${user.username}`}
            // this is for progressive enhancement
            onClick={(e) => e.preventDefault()}
            className="flex items-center gap-2"
          >
            <img
              className="h-9 w-9 rounded-full object-cover ring-2 ring-background shadow-sm"
              alt={user.name ?? user.username}
              src={getUserImgSrc(user.image?.id)}
            />
            <span className="ml-1 text-sm font-medium hidden md:inline"> {/* Hide on mobile initially */}
              {user.name ? user.name.split(' ')[0] : user.username}
            </span>
            <ChevronDown className="h-3.5 w-3.5 opacity-70 transition-transform duration-200 group-hover:rotate-180" />
          </Link>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuPortal>
        <DropdownMenuContent
          sideOffset={8}
          align="end"
          className="w-60 overflow-hidden p-0"
        >
          <div className="border-b border-border/40 bg-muted/20 px-3 py-2">
            <p className="text-xs text-muted-foreground">Logado como</p>
            <p className="text-sm font-medium truncate">
              {user.name ?? user.username}
            </p>
          </div>
          <div className="p-1">
             <DropdownMenuItem
              asChild
              className="flex gap-2 cursor-pointer rounded-md"
            >
              <Link prefetch="intent" to={`/usuario/${user.username}`}>
                <Icon className="h-4 w-4 text-muted-foreground" name="avatar" />
                <span>Minha conta</span>
              </Link>
            </DropdownMenuItem>
            <DropdownMenuItem
              asChild
              className="flex gap-2 cursor-pointer rounded-md"
            >
              <Link prefetch="intent" to={`/garagem`}>
                <Car className="h-4 w-4 text-muted-foreground" />
                <span>Minha Garagem</span>
              </Link>
            </DropdownMenuItem>
            <DropdownMenuItem
              asChild
              className="flex gap-2 cursor-pointer rounded-md"
            >
              <Link prefetch="intent" to={`/anuncios`}>
                <Tag className="h-4 w-4 text-muted-foreground" />
                <span>Meus Anúncios</span>
              </Link>
            </DropdownMenuItem>
             <DropdownMenuItem
              asChild
              className="flex gap-2 cursor-pointer rounded-md"
            >
               <Link prefetch="intent" to={`/vender`}>
                <PlusCircle className="h-4 w-4 text-muted-foreground" />
                 <span>Anunciar Carro</span>
              </Link>
            </DropdownMenuItem>
          </div>
          <div className="border-t border-border/40 p-1">
            <DropdownMenuItem
              asChild
              className="flex gap-2 cursor-pointer rounded-md text-red-500 focus:text-red-500 focus:bg-red-50 dark:focus:bg-red-950/30"
              // this prevents the menu from closing before the form submission is completed
              onSelect={(event) => {
                event.preventDefault();
                submit(formRef.current);
              }}
            >
              <Form action="/logout" method="POST" ref={formRef}>
                <Icon className="h-4 w-4" name="exit" />
                <button type="submit">Sair</button>
              </Form>
            </DropdownMenuItem>
          </div>
        </DropdownMenuContent>
      </DropdownMenuPortal>
    </DropdownMenu>
  );
}


// ScrollHandler component for client-side scroll detection
function ScrollHandler({ setIsScrolled }: { setIsScrolled: (isScrolled: boolean) => void }) {
  useEffect(() => {
    setIsScrolled(window.scrollY > 10); // Set initial state
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [setIsScrolled]);
  return null;
}

export function Header({
  user,
  isOnCarrosPage,
  isOnDashboardPage,
  isOnMapaPage,
}: HeaderProps) {
  const [isScrolled, setIsScrolled] = useState(false);

  // Adjust announce car link based on user auth
  const adjustedNavigation = navigation.map(section => ({
    ...section,
    items: section.items.map(item => ({
      ...item,
      href: item.name === 'Anunciar Carro' && !user ? '/login' : item.href,
    })),
  }));

  return (
    <>
      <ClientOnly>{() => <ScrollHandler setIsScrolled={setIsScrolled} />}</ClientOnly>
      <header
        className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 py-2.5 px-6 ${
          isOnCarrosPage || isOnDashboardPage
            ? 'bg-white shadow-md dark:bg-background'
            : isOnMapaPage
              ? 'bg-white/90 backdrop-blur-md shadow-md dark:bg-background/90 !py-1.5'
              : isScrolled
                ? 'bg-white/90 backdrop-blur-md shadow-sm dark:bg-background/90'
                : 'bg-transparent'
        }`}
      >
        <div className="max-w-7xl mx-auto flex items-center justify-between">
          {/* Logo */}
          <Link
            to="/"
            prefetch="intent"
            className="text-2xl font-display font-bold text-foreground transition-all duration-300 hover:opacity-80"
          >
            <div className="flex items-center">
              <img
                className="aspect-square h-8 w-8 md:h-9 md:w-9 mr-2"
                src="/img/logo.webp"
                alt="Logo Carregados"
              />
              Carregados
              <span className="text-primary">.</span>
            </div>
          </Link>

          {/* Desktop Navigation using shadcn NavigationMenu */}
          <NavigationMenu className="hidden md:flex items-center space-x-1">
            <NavigationMenuList>
              {adjustedNavigation.map((section) => (
                <NavigationMenuItem key={section.name} className="bg-transparent hover:bg-transparent">
                  <NavigationMenuTrigger className="font-semibold text-gray-600 bg-transparent hover:bg-transparent">
                    {/* You can add the section icon here if desired */}
                    {/* {section.icon && <section.icon className="h-4 w-4 mr-2" />} */}
                    {section.name}
                  </NavigationMenuTrigger>
                  <NavigationMenuContent>
                    <div className="grid gap-3 p-4 md:w-[400px] lg:w-[500px]">
                      {section.items.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className="flex p-3 items-start gap-3 rounded-md hover:bg-accent transition-colors"
                          prefetch="intent"
                        >
                          {item.icon && (
                             <div className="mt-0.5"> {/* Optional: Adjust icon alignment */}
                                <item.icon className="h-5 w-5 text-primary flex-shrink-0" />
                             </div>
                          )}
                          <div>
                            <div className="text-sm font-medium leading-none">{item.name}</div>
                            <p className="text-sm text-muted-foreground mt-1 line-clamp-2">
                              {item.description}
                            </p>
                          </div>
                        </Link>
                      ))}
                    </div>
                  </NavigationMenuContent>
                </NavigationMenuItem>
              ))}
            </NavigationMenuList>
          </NavigationMenu>

          {/* Right side actions: User Dropdown/Login Button & Mobile Menu */}
          <div className={`flex items-center gap-4 ${isOnMapaPage ? 'py-0' : 'py-1'}`}>
             {user ? (
              <div className="hidden md:block">
                <UserDropdown user={user} />
              </div>
            ) : (
              <Button
                asChild
                className="hidden md:flex shadow-sm rounded-full font-semibold"
                variant="default"
                size="sm"
              >
                <Link prefetch="intent" to="/login">
                  Entrar
                </Link>
              </Button>
            )}

            {/* Mobile menu button */}
             <Sheet>
              <SheetTrigger
                className="block rounded-md px-2.5 py-2 text-gray-700 hover:bg-gray-100/80 dark:text-gray-200 dark:hover:bg-gray-800/60 md:hidden"
                aria-label="Abrir menu"
              >
                <Menu size={24} />
              </SheetTrigger>
              <SheetContent className="flex w-full max-w-xs flex-col overflow-hidden p-0">
                <SheetHeader className="border-b border-border/40 px-4 py-3">
                  <SheetTitle className="text-left">Menu</SheetTitle>
                </SheetHeader>
                <div className="flex-1 overflow-y-auto px-4 py-4">
                  <div className="flex flex-col space-y-5">
                    {/* User Profile Section (if logged in) */}
                    {user && (
                      <div className="flex items-center gap-3 rounded-lg bg-muted/30 p-3 shadow-sm">
                        <img
                          className="h-10 w-10 rounded-full object-cover shadow-sm"
                          alt={user.name ?? user.username}
                          src={getUserImgSrc(user.image?.id)}
                        />
                        <div className="flex flex-col">
                          <span className="font-medium">
                            {user.name ?? user.username}
                          </span>
                          {/* Optional: Add username or email below */}
                          {/* <span className="text-xs text-muted-foreground">
                            {user.username}
                          </span> */}
                        </div>
                      </div>
                    )}

                    {/* Main Navigation Section */}
                    <div className="flex flex-col gap-4">
                      <h3 className="text-xs font-semibold uppercase text-muted-foreground tracking-wider">
                        Navegação Principal
                      </h3>
                      {adjustedNavigation.map((section) => (
                        <div
                          key={section.name}
                          className="overflow-hidden rounded-lg border border-border/40 bg-card shadow-sm"
                        >
                          <h3 className="flex items-center gap-2 border-b border-border/40 bg-muted/30 px-3 py-2 text-sm font-medium text-card-foreground">
                            {section.icon ? <section.icon className="h-4 w-4" /> : null}
                            {section.name}
                          </h3>
                          <div className="flex flex-col p-1">
                            {section.items.map((item) => {
                              // Skip "Anunciar Carro" here if user is logged in, it will be in "Minha Conta"
                              if (user && item.name === 'Anunciar Carro') {
                                return null;
                              }
                              return (
                                <SheetClose key={item.name} asChild>
                                  <Link
                                    prefetch="intent"
                                    className="flex items-center gap-2 rounded-md px-3 py-2 text-sm transition-colors hover:bg-muted/50"
                                    to={item.href}
                                  >
                                    {item.icon && (
                                      <item.icon className="h-4 w-4 text-muted-foreground" />
                                    )}
                                    <span>{item.name}</span>
                                  </Link>
                                </SheetClose>
                              );
                            })}
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* User Account Section (if logged in) */}
                    {user && (
                      <div className="flex flex-col gap-4">
                        <h3 className="text-xs font-semibold uppercase text-muted-foreground tracking-wider">
                          Minha Conta
                        </h3>
                        <div className="overflow-hidden rounded-lg border border-border/40 bg-card shadow-sm">
                          <div className="flex flex-col p-1">
                             <SheetClose asChild>
                                <Link
                                  prefetch="intent"
                                  className="flex items-center gap-2 rounded-md px-3 py-2 text-sm transition-colors hover:bg-muted/50"
                                  to={`/usuario/${user.username}`}
                                >
                                  <Icon name="avatar" className="h-4 w-4 text-muted-foreground" />
                                  <span>Meu Perfil</span>
                                </Link>
                              </SheetClose>
                            <SheetClose asChild>
                              <Link
                                prefetch="intent"
                                className="flex items-center gap-2 rounded-md px-3 py-2 text-sm transition-colors hover:bg-muted/50"
                                to={`/garagem`}
                              >
                                <Car className="h-4 w-4 text-muted-foreground" />
                                <span>Minha Garagem</span>
                              </Link>
                            </SheetClose>
                            <SheetClose asChild>
                              <Link
                                prefetch="intent"
                                className="flex items-center gap-2 rounded-md px-3 py-2 text-sm transition-colors hover:bg-muted/50"
                                to={`/anuncios`}
                              >
                                <Tag className="h-4 w-4 text-muted-foreground" />
                                <span>Meus Anúncios</span>
                              </Link>
                            </SheetClose>
                            <SheetClose asChild>
                              <Link
                                prefetch="intent"
                                className="flex items-center gap-2 rounded-md px-3 py-2 text-sm transition-colors hover:bg-muted/50"
                                to={`/vender`} // Direct link to create new ad
                              >
                                <PlusCircle className="h-4 w-4 text-muted-foreground" />
                                <span>Anunciar Carro</span>
                              </Link>
                            </SheetClose>
                          </div>
                        </div>
                         {/* Logout Button */}
                        <SheetClose asChild>
                            <Form action="/logout" method="POST">
                              <button
                                type="submit"
                                className="flex w-full items-center gap-2 rounded-md px-3 py-2 text-sm text-red-500 transition-colors hover:bg-red-50 dark:hover:bg-red-950/30 border border-border/40 bg-card shadow-sm"
                              >
                                <Icon name="exit" className="h-4 w-4" />
                                <span>Sair</span>
                              </button>
                            </Form>
                          </SheetClose>
                      </div>
                    )}

                    {/* Login Button (if logged out) */}
                    {!user && (
                      <SheetClose asChild>
                        <Link
                          prefetch="intent"
                          className="flex w-full items-center mt-auto" // Push to bottom if possible, or adjust layout
                          to="/login"
                        >
                          <Button
                            variant="default"
                            className="w-full bg-primary hover:bg-primary/90 shadow-sm"
                          >
                            Entrar / Cadastrar
                          </Button>
                        </Link>
                      </SheetClose>
                    )}
                  </div>
                </div>
              </SheetContent>
            </Sheet>
          </div>
        </div>
      </header>
    </>
  );
} 